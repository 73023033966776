import { createReducer, on, type Action } from '@ngrx/store';
import * as CountriesActions from '../actions/countries.actions';
import {
  countriesInitialState,
  type CountriesState,
} from '../states/countries.state';

export const countriesFeatureKey = 'countries';

const reducer = createReducer(
  countriesInitialState,
  on(
    CountriesActions.loadCountries,
    (state): CountriesState => ({
      ...state,
      areCountriesLoaded: false,
      areCountriesLoading: true,
    }),
  ),
  on(
    CountriesActions.loadCountriesSuccess,
    (state, { countries }): CountriesState => ({
      ...state,
      areCountriesLoaded: true,
      areCountriesLoading: false,
      countries,
    }),
  ),
  on(
    CountriesActions.loadCountriesFail,
    (state): CountriesState => ({
      ...state,
      areCountriesLoaded: false,
      areCountriesLoading: false,
    }),
  ),
);

export function countriesReducer(
  state: CountriesState | undefined,
  action: Action,
): CountriesState {
  return reducer(state, action);
}
