export interface Country {
  name: string;
  longCode: string;
  code: string;
  phoneCode: string | null;
}

export interface CountriesState {
  areCountriesLoading: boolean;
  areCountriesLoaded: boolean;
  countries: Country[];
}

export const countriesInitialState: CountriesState = {
  areCountriesLoading: false,
  areCountriesLoaded: false,
  countries: [],
};
