import { createAction, props } from '@ngrx/store';
import type { Country } from '../states/countries.state';

export const loadCountries = createAction('[Countries] Load Countries');

export const loadCountriesSuccess = createAction(
  '[Countries] Load Countries Success',
  props<{ countries: Country[] }>(),
);

export const loadCountriesFail = createAction(
  '[Countries] Load Countries Fail',
  props<{ message: string }>(),
);
